<template>
    <div class="wrapper clickable">
        <div class="collbtn" :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="`collapse-${term.id}`" @click="visible = !visible">
            <section id="term">
                <div class="term">
                    <div><b> {{ term.term }}</b></div>
                    <div style="display: flex; justify-content: end;">
                        <div v-for="category in getCategoriesByTermId" :key="category.id">
                            <b-icon v-if="category.id > 1" class="term-icons" icon="square-fill"
                                :style="{ 'color': category.color }"></b-icon>
                        </div>
                    </div>
                    <b-icon id="down" icon="chevron-down"></b-icon>
                </div>
            </section>
        </div>
        <b-collapse :id="`collapse-${term.id}`" v-model="visible">
            <b-card no body style="border: none">
                <b-card-body class="text-left border-top">
                    <b-card-text>
                        <span v-html="term.definition"></span>
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-collapse>
    </div>
</template>
<script>


export default {
    name: "collapse-reflection",
    components: {

    },
    props: {
        term: Object,

    },
    computed: {
        getCategoriesByTermId() {
            return this.$store.getters['survey/getCategoriesByTermId'](this.term)
        },
        getTermsByGlossarFilter() {
            return this.$store.getters['survey/getTermsByGlossarFilter']
        },

    },
    methods: {
        projectReflection() {
            return this.$store.getters['survey/getProjectReflection']
        },
    },
    data() {
        return {
            visible: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 1rem;
}

section#term {
    display: flex;
    width: 100%;
    flex-direction: column;

    .term {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 80% auto 2%;
        grid-column-gap: 1.5rem;
        height: 3rem;
        align-content: center;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;
    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon#down {
        transform: rotate(180deg);
    }

    .term-icons {
        margin-right: 16px;
    }
}
</style>
