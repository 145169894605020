<template>
    <div class="row grow w-100">
        <div class="col-1">
            <NavSidebar></NavSidebar>
        </div>
        <div class="main col-11 h-100 py-3 padding-subpages">
            <NavHeader tutorialName="none" buttonLabel="Zur Projektübersicht" chevron="true" chevronLeft="true"
                        :link="true" linkTo="/uebersicht"></NavHeader>
                        
            <HeaderCard info="false" :icons="[]" info_color='red'>
                <template #title>
                    Glossar
                </template>
                <template #description>
                   <p>Das Glossar beinhaltet im Tool benutzte <strong>Begrifflichkeiten</strong> des Themengebiets ELSI. Sie beziehen
                    sich auf den Kontext von Gesundheits- und Pflegetechnologien. Klicken Sie auf die einzelnen Zeilen, um
                    die jeweiligen Erklärungen der Begriffe zu lesen.</p>
                </template>
            </HeaderCard>

            <section id="sort">
                <div>
                    <b-form-input class="search" size="lg" id="input-default" type="search" v-model="searchQueryGlossar"
                        placeholder="Suche"></b-form-input>
                    <SortSidebar type="glossar"></SortSidebar>
                </div>
                <section id="terms">
                    <div class="terms">
                        <div v-for="term in terms" :key=term.id>
                            <CollapseGlossar :term="term" v-if="term.isGlossar"></CollapseGlossar>
                        </div>
                    </div>

                </section>
            </section>

        </div>
    </div>
</template>

<script>

import NavHeader from "../components/Nav_Header.vue"
import HeaderCard from "../components/Header_Card.vue"
import SortSidebar from "../components/Sort_Sidebar.vue"
import NavSidebar from "../components/Nav_Sidebar.vue"
import CollapseGlossar from "../components/Collapse_Glossar.vue"

export default {
    name: 'glossar',
    components: {
        NavHeader,
        HeaderCard,
        SortSidebar,
        NavSidebar,
        CollapseGlossar
    },
    computed: {
        getRouter() {
            return this.$route.path
        },
        getGlossarFilterArray() {
            return this.$store.getters['survey/getGlossarFilterArray']
        },
        terms() {
            return this.$store.getters['survey/getTermsByGlossarFilter']
        },
        searchQueryGlossar: {
            get() {
                return this.$store.state.glossarSearchQuery
            },
            set(val) {
                // this.setGlossarFilterAll()
                this.$store.commit('survey/SET_GLOSSAR_SEARCH_QUERY', val)
            }
        },
    },
    watch: {
    },
    methods: {
        backNavigation() {
            this.$router.back()
        }
        ,
        getTermByName(termName) {
            return this.$store.getters['survey/getTermByName'](termName)
        },
        emptyGlossarFilter() {
            let payload = {}
            payload = { value: this.isFilterTerm }
            this.$store.commit("survey/UPDATE_GLOSSAR_FILTER", payload)
        },
        setGlossarFilterAll() {
            let payload = {}
            payload = { value: 1 }
            this.$store.commit("survey/UPDATE_GLOSSAR_FILTER", payload)
        },
        disableFilterTerm() {
            this.isFilter = false
            this.isFilterTerm = ''
        },
    },
    beforeMount() {
        if (this.$route.params.filter === 'all') {
            this.isFilter = false
            let payload = { value: 1 }
            this.$store.commit("survey/UPDATE_GLOSSAR_FILTER", payload)
        }

        else {
            this.isFilter = true
            this.isFilterTerm = this.$route.params.filter
            this.emptyGlossarFilter()

        }
    },
    data() {
        return {
            isFilter: false,
            isFilterTerm: '',
        }
    }
}

</script>

<style lang="scss" scoped>
section#sort {
    display: flex;
    flex-direction: column;
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: 310px auto;
    height: fit-content;
    margin-top: 32px;

    .sort {
        height: 200px;
    }

    .search {
        padding: 20px 32px 20px 32px;
        gap: 12px;
        border-radius: 6px;
        border: none;
        margin-bottom: 32px;
    }
}

section#terms {
    display: flex;
    flex-direction: column;

}
</style>
